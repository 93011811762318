import React from "react"
import { Link } from "gatsby"

import {
  Page,
  HomeView,
  Heading,
  Text,
  Button,
  Icon,
} from "marketplace/app/components"
import CoinsRed from "marketplace/app/images/icons/coins-red.svg"
import CoinsBlue from "marketplace/app/images/icons/coins-blue.svg"

const IndexPage = () => (
  <Page seo="Home" sx={{ position: "relative" }}>
    <Icon path={CoinsRed} sx={{ position: "absolute", right: 0, top: 0 }} />
    <Icon path={CoinsBlue} sx={{ position: "absolute", left: 0, bottom: 0 }} />

    <HomeView>
      <Heading variant="text.homeTitle" sx={{ mb: "32px" }}>
        Say hello to Geodb's new data market!
      </Heading>
      <Heading variant="text.homeSubtitle" sx={{ mb: "24px" }}>
        Find your data of interest. <br /> Pay with $GEOs, our data currency
      </Heading>
      <Text
        variant="text.textBody"
        sx={{
          mb: "50px",
          maxWidth: "780px",
          width: "100%",
          // alignSelf: "center",
        }}
      >
        In the marketplace you can freely explore the availability of data. To
        create a query of interest, request the budget for the dataset, buy it and
        access your previous purchases, you only need MetaMask.
      </Text>
      <Link to="/search">
        <Button
        // variant="buttons.primary"
        // sx={{ mb: "30px" }}
        >
          <Text variant="text.textSmall">Search and buy</Text>
        </Button>
      </Link>
    </HomeView>
  </Page>
)

export default IndexPage
